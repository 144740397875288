<template>
  <Map :fullView="true"/>
</template>
<script>
import {socialvue} from '@/config/pluginInit'
import api from "@/api/api"
import Map from "./Components/Map"

export default {
  name: 'MapPage',
  props: {},
  clients: null,
  components: {Map},

  mounted() {
    socialvue.index()
    api
        .post("client/search", {})
        .then((response) => {
          if (response.data.status) {
            this.clients = response.data.clients
            this.toggleInfo(this.clients[0], 0);
          }
        })
  }
  ,

  computed: {}
  ,
  data: () => ({
    femaleImage: require('../../assets/images/user/female.jpg'),
    maleImage: require('../../assets/images/user/male.jpg'),
    marker: require("../../assets/images/marker.png"),
    infoPosition: null,
    infoFullName: null,
    infoImg: null,
    infoAge: null,
    infoId: null,
    InfoProfile: null,
    InfoSex: null,
    infoOpened: false,
    infoCurrentKey: null,
    infoOptions: {
      pixelOffset: {
        width: 0,
        height: -35
      }
    },
    clients: null,
    mapStyle: {
      mapTypeControl: false,
      scaleControl: false,
      streetViewControl: false,
      rotateControl: false,
      fullscreenControl: false,
      disableDefaultUi: true,
      // other properties...
      styles: [
        {elementType: 'geometry', stylers: [{color: '#242f3e'}]},
        {elementType: 'labels.text.stroke', stylers: [{color: '#242f3e'}]},
        {elementType: 'labels.text.fill', stylers: [{color: '#746855'}]},
        {
          featureType: 'administrative.locality',
          elementType: 'labels.text.fill',
          stylers: [{color: '#d59563'}]
        },
        {
          featureType: 'poi',
          elementType: 'labels.text.fill',
          stylers: [{color: '#d59563'}]
        },
        {
          featureType: 'poi.park',
          elementType: 'geometry',
          stylers: [{color: '#263c3f'}]
        },
        {
          featureType: 'poi.park',
          elementType: 'labels.text.fill',
          stylers: [{color: '#6b9a76'}]
        },
        {
          featureType: 'road',
          elementType: 'geometry',
          stylers: [{color: '#38414e'}]
        },
        {
          featureType: 'road',
          elementType: 'geometry.stroke',
          stylers: [{color: '#212a37'}]
        },
        {
          featureType: 'road',
          elementType: 'labels.text.fill',
          stylers: [{color: '#9ca5b3'}]
        },
        {
          featureType: 'road.highway',
          elementType: 'geometry',
          stylers: [{color: '#746855'}]
        },
        {
          featureType: 'road.highway',
          elementType: 'geometry.stroke',
          stylers: [{color: '#1f2835'}]
        },
        {
          featureType: 'road.highway',
          elementType: 'labels.text.fill',
          stylers: [{color: '#f3d19c'}]
        },
        {
          featureType: 'transit',
          elementType: 'geometry',
          stylers: [{color: '#2f3948'}]
        },
        {
          featureType: 'transit.station',
          elementType: 'labels.text.fill',
          stylers: [{color: '#d59563'}]
        },
        {
          featureType: 'water',
          elementType: 'geometry',
          stylers: [{color: '#17263c'}]
        },
        {
          featureType: 'water',
          elementType: 'labels.text.fill',
          stylers: [{color: '#515c6d'}]
        },
        {
          featureType: 'water',
          elementType: 'labels.text.stroke',
          stylers: [{color: '#17263c'}]
        }
      ]
    }
  }),
  methods: {
    goToProfile(userId) {
      this.$router.push({path: '/'})
      this.$router.replace({path: '/profile/' + userId})
    },
    getPosition: function (marker) {
      if (marker.location) {
        return {
          lat: parseFloat(marker.location[0]),
          lng: parseFloat(marker.location[1])
        }
      } else return null
    },
    toggleInfo: function (marker, key) {
      this.infoPosition = this.getPosition(marker);
      this.infoFullName = marker.first_name + " " + marker.last_name;
      this.infoAge = marker.age;
      if (marker.photo) {
        this.infoImg = this.$mediaBaseUrl + marker.photo.url
      } else if (marker.gender == 1) {
        this.infoImg = this.maleImage
      } else if (marker.gender == 2) {
        this.infoImg = this.femaleImage
      } else if (marker.gender == 3) {
        this.infoImg = this.femaleImage
      }

      this.infoId = marker.id
      if (this.infoCurrentKey == key) {
        this.infoOpened = !this.infoOpened;
      } else {
        this.infoOpened = true;
        this.infoCurrentKey = key;
      }
    }
  }
}
</script>

<style>
.gm-style-iw-d {

}

.gm-style-iw-d td {
  padding-top: 5px;
}

.gm-style-iw-d div {

  color: black !important;
  padding: 4px auto;
  text-align: center;
  width: 100%;
  font-weight: bolder;
  font-size: 14px;
}


.gm-style-iw-d img {
  max-width: 100px;
}

.gm-style-iw-d a {
  color: #ef476f !important;
  cursor: pointer !important;
}

a[href^="http://maps.google.com/maps"] {
  display: none !important
}

a[href^="https://maps.google.com/maps"] {
  display: none !important
}

.gmnoprint a, .gmnoprint span, .gm-style-cc {
  display: none;
}

.gmnoprint div {
  background: none !important;
}
</style>
